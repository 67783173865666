var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "View"
  }, [_c('div', [_c('div', {
    staticStyle: {
      "display": "flex",
      "justify-content": "space-between",
      "background": "#F7F7F8 100%",
      "height": "48px",
      "align-items": "center",
      "padding": "0px 17px"
    }
  }, [_c('div', {
    staticClass: "info-text"
  }, [_vm._v(_vm._s(_vm.getname) + "的承诺书")])]), _vm._l(_vm.list, function (item, index) {
    return _c('div', {
      key: index,
      staticClass: "View-box"
    }, [_c('div', {
      staticClass: "View-box-rot"
    }, [_c('div', {
      staticClass: "View-box-index"
    }, [_vm._v(_vm._s(item.id))]), _c('div', {
      class: item.textType !== 2 ? `View-box-text` : `View-box-text-2`
    }, [_vm._v("      " + _vm._s(item.fill))])]), item.textType == 1 ? _c('div', {
      staticClass: "View-box-button"
    }, [_c('van-button', {
      class: _vm.listvalue[index].classno,
      attrs: {
        "type": "primary",
        "size": "normal"
      },
      on: {
        "click": function ($event) {
          _vm.type == 'view' ? _vm.fales : _vm.styleprimary(index, 1);
        }
      }
    }, [_vm._v("否")]), _c('van-button', {
      class: _vm.listvalue[index].classyes,
      staticStyle: {
        "margin-right": "16px"
      },
      attrs: {
        "type": "primary",
        "size": "normal"
      },
      on: {
        "click": function ($event) {
          _vm.type == 'view' ? _vm.fales : _vm.styleprimary(index, 2);
        }
      }
    }, [_vm._v("是")])], 1) : item.textType == 2 ? _c('van-field', {
      attrs: {
        "readonly": _vm.type == 'view',
        "name": "visitName"
      },
      model: {
        value: _vm.listvalue[index].value,
        callback: function ($$v) {
          _vm.$set(_vm.listvalue[index], "value", $$v);
        },
        expression: "listvalue[index].value"
      }
    }) : _vm._e()], 1);
  })], 2), _c('div', {
    staticClass: "action-sheet-bnt"
  }, [_c('div', {
    staticClass: "submit-4",
    on: {
      "click": _vm.initno
    }
  }, [_vm._v("取消")]), _c('div', {
    staticClass: "bnt-submit",
    staticStyle: {
      "flex": "1"
    }
  }, [_c('van-button', {
    attrs: {
      "round": "",
      "block": "",
      "type": "info",
      "native-type": "submit"
    },
    on: {
      "click": _vm.submit
    }
  }, [_vm._v("确认")])], 1)]), _vm.type == 'view' ? _c('div', {
    staticClass: "about-bnt"
  }, [_c('van-button', {
    staticClass: "about-bnt-submit",
    attrs: {
      "round": "",
      "block": "",
      "type": "info",
      "native-type": "submit"
    },
    on: {
      "click": _vm.initno
    }
  }, [_vm._v("返回")])], 1) : _vm._e()]);
};
var staticRenderFns = [];
export { render, staticRenderFns };