var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "viewInformation"
  }, [_c('div', _vm._l(_vm.list, function (item, index) {
    return _c('div', {
      key: index,
      staticClass: "viewInformation-box"
    }, [_c('div', {
      staticStyle: {
        "width": "100%"
      },
      on: {
        "click": function ($event) {
          return _vm.mationClick(item);
        }
      }
    }, [_c('div', {
      staticClass: "viewInformation-box-text"
    }, [_c('div', {
      staticClass: "viewInformation-box-text-0"
    }, [_vm._v("到访时间：" + _vm._s(_vm.formatDate(new Date(item.visitStartTime))) + " 至 " + _vm._s(_vm.formatDate(new Date(item.visitEndTime))))]), _c('div', {
      staticClass: "viewInformation-box-text-1",
      style: _vm.style[item.approvalState]
    }, [_vm._v(_vm._s(_vm.type[item.approvalState]))])]), _c('div', {
      staticClass: "viewInformation-box-text-2",
      staticStyle: {
        "margin-bottom": "8px"
      }
    }, [_vm._v("到访人：" + _vm._s(item.visitName))]), _c('div', {
      staticClass: "viewInformation-box-text-2",
      staticStyle: {
        "margin-bottom": "8px"
      }
    }, [_vm._v("到访区域：" + _vm._s(item.visitGardenName.toString().replace(/,/g, "、")))]), _c('div', {
      staticClass: "viewInformation-box-text-2"
    }, [_vm._v("状态信息：" + _vm._s(item.cause))])]), item.approvalState == 4 ? _c('div', {
      staticClass: "viewInformation-box-button-0"
    }, [_c('van-button', {
      staticStyle: {
        "background": "#FFFFFF",
        "border": "1px solid rgba(0,149,217,1)",
        "border-radius": "6px",
        "font-family": "PingFangSC-Regular",
        "font-size": "14px",
        "color": "#0095D9",
        "letter-spacing": "0",
        "text-align": "center",
        "line-height": "20px",
        "font-weight": "400"
      },
      attrs: {
        "type": "primary",
        "size": "normal"
      },
      on: {
        "click": function ($event) {
          return _vm.edit(index);
        }
      }
    }, [_vm._v("修改")]), _c('van-button', {
      staticStyle: {
        "background": "#FFFFFF",
        "border": "1px solid rgba(128,128,128,1)",
        "border-radius": "6px",
        "font-family": "PingFangSC-Regular",
        "font-size": "14px",
        "color": "#808080",
        "letter-spacing": "0",
        "text-align": "center",
        "line-height": "20px",
        "margin-right": "12px"
      },
      attrs: {
        "type": "primary",
        "size": "normal"
      },
      on: {
        "click": function ($event) {
          return _vm.setremove(index);
        }
      }
    }, [_vm._v("删除")])], 1) : _vm._e(), item.approvalState == 2 ? _c('div', {
      staticClass: "viewInformation-box-button-0"
    }, [_c('van-button', {
      staticStyle: {
        "background": "#F24130",
        "border": "1px solid #F24130",
        "border-radius": "6px",
        "font-family": "PingFangSC-Regular",
        "font-size": "14px",
        "color": "#FFFFFF",
        "letter-spacing": "0",
        "text-align": "center",
        "line-height": "20px",
        "font-weight": "400"
      },
      attrs: {
        "type": "primary",
        "size": "normal"
      },
      on: {
        "click": function ($event) {
          return _vm.withdraw(index);
        }
      }
    }, [_vm._v("取消预约")])], 1) : _vm._e(), item.approvalState == 1 ? _c('div', {
      staticClass: "viewInformation-box-button-0"
    }, [_c('van-button', {
      staticStyle: {
        "background-image": "linear-gradient(227deg, #59CBE8 0%, #0091DA 100%)",
        "border": "0px solid #F24130",
        "border-radius": "6px",
        "font-family": "PingFangSC-Regular",
        "font-size": "14px",
        "padding": "0px",
        "color": "#FFFFFF",
        "letter-spacing": "0",
        "text-align": "center",
        "line-height": "20px",
        "font-weight": "400"
      },
      attrs: {
        "type": "primary",
        "size": "normal"
      },
      on: {
        "click": function ($event) {
          return _vm.qrcode(index);
        }
      }
    }, [_vm._v("展示二维码")]), _c('van-button', {
      staticStyle: {
        "background": "#F24130",
        "border": "1px solid #F24130",
        "border-radius": "6px",
        "font-family": "PingFangSC-Regular",
        "font-size": "14px",
        "color": "#FFFFFF",
        "letter-spacing": "0",
        "text-align": "center",
        "line-height": "20px",
        "font-weight": "400",
        "margin-right": "16px"
      },
      attrs: {
        "type": "primary",
        "size": "normal"
      },
      on: {
        "click": function ($event) {
          return _vm.withdraw(index);
        }
      }
    }, [_vm._v("取消预约")])], 1) : _vm._e()]);
  }), 0)]);
};
var staticRenderFns = [];
export { render, staticRenderFns };