import "core-js/modules/es.array.push.js";
import { Dialog } from 'vant';
import { bookList, updateApprovalState, remove } from '../api/article';
export default {
  data() {
    return {
      list: [],
      type: {
        0: '未通过',
        1: '已通过',
        2: '审核中',
        3: '已过期',
        4: '草稿',
        5: '取消预约',
        6: '异常'
      },
      style: {
        0: '',
        1: 'color: #0095D9 !important;',
        2: 'color: #FA6400 !important;',
        3: 'color: #CCCCCC !important;',
        4: '',
        5: '',
        6: ''
      }
    };
  },
  created() {
    this.getBookList();
  },
  methods: {
    withdraw(e) {
      Dialog.confirm({
        message: '确定取消预约？'
      }).then(res => {
        updateApprovalState({
          approvalState: 5,
          id: this.list[e].id
        }).then(v => {
          this.getBookList();
        });
      });
    },
    getBookList() {
      bookList().then(res => {
        console.log(res, 'res.data.data');
        this.list = res.data.data;
        if (!res.data.data.length) {
          Dialog({
            message: '未找到访客预约记录'
          }).then(() => {
            this.$router.push('/');
          });
        }
        console.log(this.formatDate(new Date(res.data.data[0].visitStartTime)), 'data');
      });
    },
    setremove(e) {
      Dialog.confirm({
        message: '确定删除记录？'
      }).then(res => {
        remove({
          id: this.list[e].id
        }).then(res => {
          this.getBookList();
        }).catch(err => {});
      });
    },
    mationClick(e) {
      this.$router.push({
        path: '/about',
        query: {
          id: e.id,
          type: 'view'
        }
      });
    },
    qrcode(e) {
      this.$router.push({
        path: '/qrcode',
        query: {
          id: this.list[e].id
        }
      });
    },
    edit(e) {
      this.$router.push({
        path: '/About',
        query: {
          id: this.list[e].id
        }
      });
    },
    formatDate(date) {
      var y = date.getFullYear();
      var m = date.getMonth() + 1;
      m = m < 10 ? '0' + m : m;
      var d = date.getDate();
      d = d < 10 ? '0' + d : d;
      return y + '-' + m + '-' + d;
    }
  }
};