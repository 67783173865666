var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticStyle: {
      "height": "100%"
    }
  }, [_vm.aboutshow == 1 ? _c('div', {
    staticClass: "about"
  }, [_vm._m(0), _c('van-form', {
    attrs: {
      "show-error": false,
      "error-message-align": "center"
    },
    on: {
      "submit": _vm.onSubmit
    }
  }, [_c('van-field', {
    attrs: {
      "readonly": "",
      "clickable": "",
      "required": _vm.type !== 'view',
      "name": "visitStartTime",
      "value": _vm.getvisitStartTime,
      "label": "到访开始时间",
      "placeholder": "请选择",
      "right-icon": _vm.type == 'view' ? '' : 'arrow-down',
      "rules": [{
        required: true,
        message: '请选择到访开始时间'
      }]
    },
    on: {
      "click": function ($event) {
        _vm.type == 'view' ? _vm.fales : _vm.showPickerclick('选择到访开始时间');
      }
    }
  }), _c('van-field', {
    attrs: {
      "readonly": "",
      "clickable": "",
      "required": _vm.type !== 'view',
      "name": "visitStartTime",
      "value": _vm.getvisitEndTime,
      "label": "到访截止时间",
      "placeholder": "请选择",
      "right-icon": _vm.type == 'view' ? '' : 'arrow-down',
      "error-message": _vm.errorMessag.getvisitStartTime,
      "rules": [{
        required: true,
        message: '请选择到访截止时间'
      }]
    },
    on: {
      "click": function ($event) {
        _vm.type == 'view' ? _vm.fales : _vm.showPickerclick('选择到访截止时间');
      }
    }
  }), _c('van-field', {
    attrs: {
      "readonly": "",
      "clickable": "",
      "name": "visitAreaName",
      "value": _vm.VisitorBookInfo.visitAreaName,
      "label": "到访单位",
      "required": _vm.type !== 'view',
      "placeholder": "请选择",
      "type": "textarea",
      "rows": "1",
      "autosize": "",
      "right-icon": _vm.type == 'view' ? '' : 'arrow-down',
      "rules": [{
        required: true,
        message: '请选择到访单位'
      }]
    },
    on: {
      "click": function ($event) {
        _vm.type == 'view' ? _vm.fales : _vm.showPickerclick('到访单位');
      }
    }
  }), _c('van-field', {
    attrs: {
      "readonly": "",
      "clickable": "",
      "name": "visitGardenName",
      "value": _vm.VisitorBookInfo.visitGardenName,
      "label": "到访园区",
      "type": "textarea",
      "rows": "1",
      "autosize": "",
      "required": _vm.type !== 'view',
      "placeholder": "请选择",
      "right-icon": _vm.type == 'view' ? '' : 'arrow-down',
      "rules": [{
        required: true,
        message: '请选择到访园区'
      }]
    },
    on: {
      "click": function ($event) {
        _vm.type == 'view' || !_vm.VisitorBookInfo.visitAreaName ? _vm.fales : _vm.showPickerclick('到访园区');
      }
    }
  }), _c('van-field', {
    attrs: {
      "name": "visitName",
      "readonly": _vm.type == 'view',
      "label": "到访人",
      "type": "textarea",
      "rows": "1",
      "autosize": "",
      "required": _vm.type !== 'view',
      "placeholder": "请输入到访人姓名",
      "rules": [{
        required: true,
        message: '请输入到访人姓名'
      }, {
        validator: _vm.validatorName,
        message: '访人姓名长度最大不能超过15字'
      }]
    },
    model: {
      value: _vm.VisitorBookInfo.visitName,
      callback: function ($$v) {
        _vm.$set(_vm.VisitorBookInfo, "visitName", $$v);
      },
      expression: "VisitorBookInfo.visitName"
    }
  }), _c('van-field', {
    attrs: {
      "name": "visitMobile",
      "readonly": _vm.type == 'view',
      "required": _vm.type !== 'view',
      "label": "到访人手机号",
      "placeholder": "请输入到访人手机号",
      "rules": [{
        required: true,
        message: '请输入手机号'
      }, {
        pattern: _vm.pattern,
        message: '请输入正确的手机号'
      }]
    },
    on: {
      "input": _vm.inputclke
    },
    model: {
      value: _vm.VisitorBookInfo.visitMobile,
      callback: function ($$v) {
        _vm.$set(_vm.VisitorBookInfo, "visitMobile", $$v);
      },
      expression: "VisitorBookInfo.visitMobile"
    }
  }), _c('van-field', {
    attrs: {
      "name": "comeCause",
      "readonly": _vm.type == 'view',
      "label": "事由",
      "rows": "1",
      "autosize": "",
      "type": "textarea",
      "required": _vm.type !== 'view',
      "placeholder": "请输入事由",
      "rules": [{
        required: true,
        message: '请输入事由'
      }, {
        validator: _vm.validator,
        message: '事由长度最大不能超过50字'
      }]
    },
    model: {
      value: _vm.VisitorBookInfo.comeCause,
      callback: function ($$v) {
        _vm.$set(_vm.VisitorBookInfo, "comeCause", $$v);
      },
      expression: "VisitorBookInfo.comeCause"
    }
  }), _vm.type !== 'view' ? _c('div', {
    staticClass: "bnt"
  }, [_c('div', {
    staticClass: "submit-4",
    on: {
      "click": function ($event) {
        return _vm.submit(4);
      }
    }
  }, [_c('van-button', [_vm._v("暂存")])], 1), _c('div', {
    staticClass: "bnt-submit",
    staticStyle: {
      "flex": "1"
    }
  }, [_c('van-button', {
    attrs: {
      "round": "",
      "block": "",
      "type": "info",
      "native-type": "submit"
    },
    on: {
      "click": function ($event) {
        return _vm.submit(2);
      }
    }
  }, [_vm._v("确认提交")])], 1)]) : _vm._e()], 1), _c('div', {
    staticClass: "about-hom-text"
  }, [_c('div', {
    staticClass: "info-text"
  }, [_vm._v("访客信息")]), _vm.type !== 'view' ? _c('van-button', {
    staticClass: "info-button",
    attrs: {
      "round": "",
      "block": "",
      "type": "info",
      "native-type": "submit"
    },
    on: {
      "click": function ($event) {
        return _vm.showPickerclick(_vm.fales);
      }
    }
  }, [_vm._v("新增访客")]) : _vm._e()], 1), _c('div', {
    staticStyle: {
      "flex": "1",
      "background": "#F7F7F8 100%"
    }
  }, _vm._l(_vm.getListDate, function (item, i) {
    var _vm$Viewlist$i, _vm$Viewlist$i2;
    return _c('div', {
      key: i,
      staticClass: "visitor-list-center-box"
    }, [_vm.type !== 'view' ? _c('van-icon', {
      staticClass: "visitor-list-center-close",
      attrs: {
        "name": "close"
      },
      on: {
        "click": function ($event) {
          return _vm.removeBy(i);
        }
      }
    }) : _vm._e(), _c('div', {
      staticClass: "visitor-list-center"
    }, [_c('div', {
      staticClass: "visitor-list-center-tite"
    }, [_c('div', [_vm._v(_vm._s(item.visitorName))]), _vm.type !== 'view' ? _c('van-icon', {
      attrs: {
        "name": "edit"
      },
      on: {
        "click": function ($event) {
          return _vm.personnelEdit(item);
        }
      }
    }) : _vm._e()], 1), _vm.healthPromiseState !== 0 ? _c('div', {
      staticClass: "visitor-list-center-listcenter"
    }, [_c('div', {
      class: `visitor-list-center-listcenter-${_vm.healthPromiseState}`
    }, [_vm._v("健康承诺书")]), _c('div', {
      staticClass: "visitor-list-center-listcenter-box"
    }, [!((_vm$Viewlist$i = _vm.Viewlist[i]) !== null && _vm$Viewlist$i !== void 0 && _vm$Viewlist$i.type) ? _c('span', [_vm._v("请填写")]) : ((_vm$Viewlist$i2 = _vm.Viewlist[i]) === null || _vm$Viewlist$i2 === void 0 ? void 0 : _vm$Viewlist$i2.type) == 2 ? _c('span', {
      staticClass: "ilinbokeno"
    }, [_vm._v("存在风险项")]) : _c('span', {
      staticClass: "ilinboke"
    }, [_vm._v("无风险项")]), _c('van-icon', {
      attrs: {
        "name": "arrow"
      },
      on: {
        "click": function ($event) {
          return _vm.vanIconView(item, i);
        }
      }
    })], 1)]) : _vm._e(), _c('div', [_c('div', {
      staticClass: "visitor-list-center-listcenter-0"
    }, [_c('div', {
      staticClass: "visitor-list-center-listcenter-1"
    }, [_vm._v("上传图片")]), _c('div', {
      staticClass: "visitor-list-center-listcenter-2"
    }, [_vm._v(_vm._s(_vm.hint ? _vm.hint : '24小时核酸记录、三天三检、行程卡'))])]), _c('div', {
      on: {
        "click": function ($event) {
          return _vm.uploaderClick(i);
        }
      }
    }, [_c('van-uploader', {
      class: `visitor-list-center-${_vm.type}`,
      attrs: {
        "preview-full-image": false,
        "disabled": _vm.type == 'view',
        "deletable": _vm.type !== 'view',
        "max-count": 3,
        "after-read": _vm.afterRead
      },
      on: {
        "click-preview": _vm.clickPreview,
        "click-upload": function ($event) {
          return _vm.afterimg(i);
        }
      },
      model: {
        value: _vm.imgurl[i],
        callback: function ($$v) {
          _vm.$set(_vm.imgurl, i, $$v);
        },
        expression: "imgurl[i]"
      }
    })], 1)])])], 1);
  }), 0), _c('van-action-sheet', {
    class: _vm.actionSheetTitle == '选择到访开始时间' || _vm.actionSheetTitle == '选择到访截止时间' ? 'action-sheet-Title' : '',
    attrs: {
      "title": _vm.actionSheetTitle
    },
    on: {
      "close": _vm.sheetclose,
      "click-overlay": _vm.sheetclose,
      "cancel": _vm.sheetclose
    },
    model: {
      value: _vm.showPicker,
      callback: function ($$v) {
        _vm.showPicker = $$v;
      },
      expression: "showPicker"
    }
  }, [!_vm.actionSheetTitle && !_vm.VisitorInfoshow ? _c('div', {
    staticClass: "content"
  }, [_c('van-tabs', {
    model: {
      value: _vm.vanTab,
      callback: function ($$v) {
        _vm.vanTab = $$v;
      },
      expression: "vanTab"
    }
  }, [_c('vanTab', {
    attrs: {
      "title": "新增访客"
    }
  }, [_c('van-form', {
    staticClass: "tabs-van-form-content",
    attrs: {
      "show-error": false
    },
    on: {
      "submit": _vm.addOnSubmit
    }
  }, [_c('van-field', {
    attrs: {
      "name": "visitorName",
      "label": "姓名",
      "required": "",
      "placeholder": "请输入姓名",
      "show-error": false,
      "rules": [{
        required: true,
        message: '请输入姓名'
      }, {
        validator: _vm.validatorName,
        message: '姓名长度最大不能超过15字'
      }]
    },
    model: {
      value: _vm.VisitorInfo.visitorName,
      callback: function ($$v) {
        _vm.$set(_vm.VisitorInfo, "visitorName", $$v);
      },
      expression: "VisitorInfo.visitorName"
    }
  }), _c('van-field', {
    attrs: {
      "name": "cardId",
      "label": "身份证号",
      "required": "",
      "placeholder": "请输入身份证号",
      "show-error": false,
      "rules": [{
        required: true,
        message: '请输入身份证号'
      }, {
        pattern: _vm.patterncard,
        message: '请输入正确的身份证号'
      }]
    },
    model: {
      value: _vm.VisitorInfo.cardId,
      callback: function ($$v) {
        _vm.$set(_vm.VisitorInfo, "cardId", $$v);
      },
      expression: "VisitorInfo.cardId"
    }
  }), _c('van-field', {
    attrs: {
      "name": "company",
      "label": "所属公司",
      "required": "",
      "placeholder": "请输入所属公司",
      "show-error": false,
      "rules": [{
        required: true,
        message: '请输入所属公司'
      }, {
        validator: _vm.validator,
        message: '所属公司长度最大不能超过50字'
      }]
    },
    model: {
      value: _vm.VisitorInfo.company,
      callback: function ($$v) {
        _vm.$set(_vm.VisitorInfo, "company", $$v);
      },
      expression: "VisitorInfo.company"
    }
  }), _c('van-field', {
    attrs: {
      "name": "visitorMobile",
      "label": "手机号",
      "required": "",
      "placeholder": "请输入手机号",
      "show-error": false,
      "rules": [{
        required: true,
        message: '请输入手机号'
      }, {
        pattern: _vm.pattern,
        message: '请输入正确的手机号'
      }]
    },
    model: {
      value: _vm.VisitorInfo.visitorMobile,
      callback: function ($$v) {
        _vm.$set(_vm.VisitorInfo, "visitorMobile", $$v);
      },
      expression: "VisitorInfo.visitorMobile"
    }
  }), _c('van-field', {
    attrs: {
      "name": "position",
      "label": "职位",
      "placeholder": "请输入职位",
      "show-error": false
    },
    model: {
      value: _vm.VisitorInfo.position,
      callback: function ($$v) {
        _vm.$set(_vm.VisitorInfo, "position", $$v);
      },
      expression: "VisitorInfo.position"
    }
  }), _c('van-field', {
    attrs: {
      "name": "carInfo",
      "label": "车辆信息",
      "placeholder": "请输入车辆信息",
      "show-error": false
    },
    model: {
      value: _vm.VisitorInfo.carInfo,
      callback: function ($$v) {
        _vm.$set(_vm.VisitorInfo, "carInfo", $$v);
      },
      expression: "VisitorInfo.carInfo"
    }
  }), _c('div', {
    staticClass: "action-sheet-form-bnt"
  }, [_c('div', {
    staticClass: "submit-4",
    on: {
      "click": _vm.noBnt
    }
  }, [_vm._v("取消")]), _c('div', {
    staticClass: "bnt-submit",
    staticStyle: {
      "flex": "1"
    }
  }, [_c('van-button', {
    attrs: {
      "round": "",
      "block": "",
      "type": "info",
      "native-type": "submit"
    }
  }, [_vm._v("确认")])], 1)])], 1)], 1), _c('vanTab', {
    attrs: {
      "title": "我的访客"
    }
  }, [_c('div', {
    staticClass: "picker-showPicker"
  }, _vm._l(_vm.listDate, function (item, idex) {
    return _c('div', {
      key: idex,
      on: {
        "click": function ($event) {
          return _vm.pickershowlistDate(idex);
        }
      }
    }, [_c('div', {
      staticClass: "picker-showPicker-box"
    }, [_c('div', [_vm._v(_vm._s(item.visitorName) + " "), _c('span', {
      staticClass: "picker-showPicker-box-visitorMobile"
    }, [_vm._v(_vm._s(item.visitorMobile))])]), item.tyeps ? _c('van-icon', {
      staticClass: "picker-showPicker-box-icon",
      attrs: {
        "name": "success"
      }
    }) : _vm._e()], 1), _c('van-divider')], 1);
  }), 0)])], 1)], 1) : _vm._e(), !_vm.actionSheetTitle && _vm.VisitorInfoshow ? _c('div', {
    staticClass: "content"
  }, [_c('van-form', {
    on: {
      "submit": _vm.addOnSubmit
    }
  }, [_c('van-field', {
    attrs: {
      "name": "visitorName",
      "label": "姓名",
      "required": "",
      "placeholder": "请输入姓名",
      "show-error": false,
      "rules": [{
        required: true,
        message: '请输入姓名'
      }, {
        validator: _vm.validatorName,
        message: '姓名长度最大不能超过15字'
      }]
    },
    model: {
      value: _vm.VisitorInfo.visitorName,
      callback: function ($$v) {
        _vm.$set(_vm.VisitorInfo, "visitorName", $$v);
      },
      expression: "VisitorInfo.visitorName"
    }
  }), _c('van-field', {
    attrs: {
      "name": "cardId",
      "label": "身份证号",
      "required": "",
      "placeholder": "请输入身份证号",
      "show-error": false,
      "rules": [{
        required: true,
        message: '请输入身份证号'
      }, {
        pattern: _vm.patterncard,
        message: '请输入正确的身份证号'
      }]
    },
    model: {
      value: _vm.VisitorInfo.cardId,
      callback: function ($$v) {
        _vm.$set(_vm.VisitorInfo, "cardId", $$v);
      },
      expression: "VisitorInfo.cardId"
    }
  }), _c('van-field', {
    attrs: {
      "name": "company",
      "label": "所属公司",
      "required": "",
      "placeholder": "请输入所属公司",
      "show-error": false,
      "rules": [{
        required: true,
        message: '请输入所属公司'
      }, {
        validator: _vm.validator,
        message: '所属公司长度最大不能超过50字'
      }]
    },
    model: {
      value: _vm.VisitorInfo.company,
      callback: function ($$v) {
        _vm.$set(_vm.VisitorInfo, "company", $$v);
      },
      expression: "VisitorInfo.company"
    }
  }), _c('van-field', {
    attrs: {
      "name": "visitorMobile",
      "label": "手机号",
      "required": "",
      "placeholder": "请输入手机号",
      "show-error": false,
      "rules": [{
        required: true,
        message: '请输入手机号'
      }, {
        pattern: _vm.pattern,
        message: '请输入正确的手机号'
      }]
    },
    model: {
      value: _vm.VisitorInfo.visitorMobile,
      callback: function ($$v) {
        _vm.$set(_vm.VisitorInfo, "visitorMobile", $$v);
      },
      expression: "VisitorInfo.visitorMobile"
    }
  }), _c('van-field', {
    attrs: {
      "name": "position",
      "label": "职位",
      "placeholder": "请输入职位",
      "show-error": false
    },
    model: {
      value: _vm.VisitorInfo.position,
      callback: function ($$v) {
        _vm.$set(_vm.VisitorInfo, "position", $$v);
      },
      expression: "VisitorInfo.position"
    }
  }), _c('van-field', {
    attrs: {
      "name": "carInfo",
      "label": "车辆信息",
      "placeholder": "请输入车辆信息",
      "show-error": false
    },
    model: {
      value: _vm.VisitorInfo.carInfo,
      callback: function ($$v) {
        _vm.$set(_vm.VisitorInfo, "carInfo", $$v);
      },
      expression: "VisitorInfo.carInfo"
    }
  }), _c('div', {
    staticClass: "action-sheet-form-bnt"
  }, [_c('div', {
    staticClass: "submit-4",
    on: {
      "click": _vm.noBnt
    }
  }, [_vm._v("取消")]), _c('div', {
    staticClass: "bnt-submit",
    staticStyle: {
      "flex": "1"
    }
  }, [_c('van-button', {
    attrs: {
      "round": "",
      "block": "",
      "type": "info",
      "native-type": "submit"
    }
  }, [_vm._v("确认")])], 1)])], 1)], 1) : _vm.actionSheetTitle && _vm.actionSheetTitle !== '选择到访开始时间' && _vm.actionSheetTitle !== '选择到访截止时间' ? _c('div', {
    staticClass: "actionSheetTitle-content"
  }, [_vm.actionSheetTitle == '到访单位' ? _c('van-search', {
    attrs: {
      "placeholder": "请输入搜索关键词"
    },
    on: {
      "input": _vm.searchOnclk
    },
    model: {
      value: _vm.value,
      callback: function ($$v) {
        _vm.value = $$v;
      },
      expression: "value"
    }
  }) : _vm._e(), _c('div', {
    staticClass: "picker-showPicker"
  }, [_c('van-list', {
    attrs: {
      "finished": _vm.finished
    },
    on: {
      "load": _vm.onLoad
    },
    model: {
      value: _vm.loading,
      callback: function ($$v) {
        _vm.loading = $$v;
      },
      expression: "loading"
    }
  }, _vm._l(_vm.showPickerBox, function (item, idex) {
    return _c('div', {
      key: idex,
      on: {
        "click": function ($event) {
          return _vm.pickershowPickerBox(idex);
        }
      }
    }, [_c('div', {
      staticClass: "picker-showPicker-box"
    }, [_vm._v(" " + _vm._s(item.name) + " "), item.tyeps ? _c('van-icon', {
      staticClass: "picker-showPicker-box-icon",
      attrs: {
        "name": "success"
      }
    }) : _vm._e()], 1), _c('van-divider')], 1);
  }), 0)], 1), _c('div')], 1) : _vm.actionSheetTitle == '选择到访开始时间' || _vm.actionSheetTitle == '选择到访截止时间' ? _c('div', {
    staticClass: "Daet-content"
  }, [_c('van-datetime-picker', {
    attrs: {
      "type": "datetime",
      "title": "选择年月日",
      "formatter": _vm.formatter,
      "min-date": _vm.minDate,
      "filter": _vm.filter
    },
    model: {
      value: _vm.visitStartTimeDate,
      callback: function ($$v) {
        _vm.visitStartTimeDate = $$v;
      },
      expression: "visitStartTimeDate"
    }
  })], 1) : _vm._e(), _c('div', {
    staticClass: "action-sheet-bnt"
  }, [_c('div', {
    staticClass: "submit-4",
    on: {
      "click": _vm.noBnt
    }
  }, [_vm._v("取消")]), _c('div', {
    staticClass: "bnt-submit",
    staticStyle: {
      "flex": "1"
    }
  }, [_c('van-button', {
    attrs: {
      "round": "",
      "block": "",
      "type": "info",
      "native-type": "submit"
    },
    on: {
      "click": _vm.tabBnt
    }
  }, [_vm._v("确认")])], 1)])]), _vm.type == 'view' ? _c('div', {
    staticClass: "about-bnt"
  }, [_c('van-button', {
    staticClass: "about-bnt-submit",
    attrs: {
      "round": "",
      "block": "",
      "type": "info",
      "native-type": "submit"
    },
    on: {
      "click": _vm.tobnt
    }
  }, [_vm._v("返回")])], 1) : _vm._e()], 1) : _vm._e(), _vm.aboutshow == 0 ? _c('div', [_c('QRcode', {
    attrs: {
      "fdId": _vm.fdId
    }
  })], 1) : _vm._e(), _vm.aboutshow == 3 ? _c('GetView', {
    attrs: {
      "listDate": _vm.ViewlistDate,
      "Viewindex": _vm.Viewindex,
      "fdId": _vm.ViewId,
      "getname": _vm.getname
    },
    on: {
      "viewInitNo": _vm.viewInitbox
    }
  }) : _vm._e()], 1);
};
var staticRenderFns = [function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "about-hom-text"
  }, [_c('div', {
    staticClass: "info-text"
  }, [_vm._v("到访信息")])]);
}];
export { render, staticRenderFns };