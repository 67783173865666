var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "qrcode"
  }, [_c('img', {
    staticClass: "qrcode-img-hom",
    attrs: {
      "src": require("../img/hom.png"),
      "alt": ""
    }
  }), _c('div', {
    class: _vm.imgClass[0]
  }, [_vm.fdId ? _c('div', {
    staticClass: "qrcode-imgurl-box"
  }, [_c('img', {
    staticClass: "qrcode-imgurl-addimg",
    attrs: {
      "src": require("../img/right.png"),
      "alt": ""
    }
  }), _c('div', {
    staticClass: "qrcode-imgurl-text"
  }, [_vm._v("预约登记填写成功")])]) : _vm._e(), _vm._v(" "), !_vm.fdId ? _c('img', {
    staticClass: "qrcode-imgurl-hom",
    attrs: {
      "src": _vm.imgurl,
      "alt": ""
    }
  }) : _vm._e()]), _c('div', {
    class: _vm.imgClass[1]
  }), _c('div', {
    staticClass: "about-View-box"
  }, [_c('div', {
    class: _vm.imgClass[2]
  }, [_c('div', {
    staticClass: "about-View-box-listClass"
  }, [!_vm.fdId ? _c('div', {
    staticClass: "about-View-box-World-img"
  }, [_c('img', {
    staticClass: "about-View-box-World-img-0",
    attrs: {
      "src": require("../img/20221209144452.png"),
      "alt": ""
    }
  })]) : _vm._e(), _c('div', {
    staticClass: "about-View-box-World"
  }, [_c('div', {
    staticClass: "about-View-box-test"
  }, [_vm._v("到访开始时间")]), _c('div', {
    staticClass: "about-View-box-View-nowrap",
    staticStyle: {
      "white-space": "nowrap",
      "overflow": "hidden",
      "text-overflow": "ellipsis"
    }
  }, [_vm._v(_vm._s(_vm.visitStartTimeAll))])]), _c('div', {
    staticClass: "about-View-box-World"
  }, [_c('div', {
    staticClass: "about-View-box-test"
  }, [_vm._v("到访截止时间")]), _c('div', {
    staticClass: "about-View-box-View-nowrap",
    staticStyle: {
      "white-space": "nowrap",
      "overflow": "hidden",
      "text-overflow": "ellipsis"
    }
  }, [_vm._v(_vm._s(_vm.visitEndTime))])]), _c('div', {
    staticClass: "about-View-box-World"
  }, [_c('div', {
    staticClass: "about-View-box-test"
  }, [_vm._v("到访单位")]), _c('div', {
    staticClass: "about-View-box-View"
  }, [_vm._v(_vm._s(_vm.getDate.visitAreaName))])]), _c('div', {
    staticClass: "about-View-box-World"
  }, [_c('div', {
    staticClass: "about-View-box-test"
  }, [_vm._v("到访园区")]), _c('div', {
    staticClass: "about-View-box-View"
  }, [_vm._v(_vm._s(_vm.getDate.visitGardenName.toString().replace(/,/g, "、")))])]), _c('div', {
    staticClass: "about-View-box-World"
  }, [_c('div', {
    staticClass: "about-View-box-test"
  }, [_vm._v("到访人")]), _c('div', {
    staticClass: "about-View-box-View"
  }, [_vm._v(_vm._s(_vm.getDate.visitName))])]), _c('div', {
    staticClass: "about-View-box-World"
  }, [_c('div', {
    staticClass: "about-View-box-test"
  }, [_vm._v("到访人手机号")]), _c('div', {
    staticClass: "about-View-box-View"
  }, [_vm._v(_vm._s(_vm.getDate.visitMobile))])]), _c('div', {
    staticClass: "about-View-box-World"
  }, [_c('div', {
    staticClass: "about-View-box-test"
  }, [_vm._v("事由")]), _c('div', {
    staticClass: "about-View-box-View"
  }, [_vm._v(_vm._s(_vm.getDate.comeCause))])]), _c('div', {
    staticClass: "about-View-box-World"
  }, [_c('div', {
    staticClass: "about-View-box-test"
  }, [_vm._v("访客")]), _c('div', {
    staticClass: "about-View-box-View"
  }, [_vm._v(_vm._s(_vm.getDate.visitorInfoName.toString().replace(/,/g, "、")))])])]), _c('van-button', {
    staticClass: "qrcode-bnt-submit",
    attrs: {
      "round": "",
      "block": "",
      "type": "info",
      "native-type": "submit"
    },
    on: {
      "click": _vm.tobnt
    }
  }, [_vm._v("返回")])], 1)])]);
};
var staticRenderFns = [];
export { render, staticRenderFns };