import "core-js/modules/es.array.push.js";
import { healthlist } from '../api/article';
import { Toast } from 'vant';
export default {
  props: ['getname', 'fdId', 'Viewindex', 'listDate'],
  data() {
    return {
      list: [],
      listvalue: [],
      type: null
    };
  },
  created() {
    var _this$$route$query;
    this.bpmhealthlist();
    this.type = (_this$$route$query = this.$route.query) === null || _this$$route$query === void 0 ? void 0 : _this$$route$query.type;
  },
  methods: {
    styleprimary(e, i) {
      const forid = e + 1;
      console.log(forid);
      if (i == 2) {
        this.listvalue[e].classyes = 'View-box-button-0';
        this.listvalue[e].classno = '';
      } else {
        this.listvalue[e].classyes = '';
        this.listvalue[e].classno = 'View-box-button-1';
      }
      this.listvalue[e].value = i;
      console.log(this.listvalue);
    },
    bpmhealthlist() {
      healthlist().then(res => {
        var _this$listDate;
        console.log(this.listDate);
        if ((_this$listDate = this.listDate) !== null && _this$listDate !== void 0 && _this$listDate.name) {
          this.list = res.data.data;
          this.listvalue = this.listDate.list;
        } else {
          this.list = res.data.data.map(v => {
            this.listvalue.push({
              textType: v.textType,
              id: v.id,
              value: null,
              classyes: '',
              classno: ''
            });
            return {
              ...v
            };
          });
        }
      });
    },
    initno() {
      var _this$listDate2;
      this.$emit('viewInitNo', {
        index: this.Viewindex,
        name: this.getname,
        id: this.fdId,
        list: this.listvalue,
        type: this === null || this === void 0 ? void 0 : (_this$listDate2 = this.listDate) === null || _this$listDate2 === void 0 ? void 0 : _this$listDate2.type
      });
      console.log('viewInitNo', 1);
    },
    submit() {
      let setToast = null;
      let type = 1;
      this.list.find((v, index) => {
        if (v.type && !this.listvalue[index].value && !setToast) {
          console.log(v.id, 1);
          setToast = v.id;
        } else {
          if (v.value == '否' && this.listvalue[index].value !== 1) {
            type = 2;
          } else if (v.value == '是' && this.listvalue[index].value !== 2) {
            type = 2;
          }
        }
      });
      if (setToast) {
        Toast({
          message: `请选择第${setToast}条必填项`,
          className: "Toast-box"
        });
      } else {
        this.$emit('viewInitNo', {
          index: this.Viewindex,
          name: this.getname,
          id: this.fdId,
          list: this.listvalue,
          type: type
        });
      }
    }
  }
};