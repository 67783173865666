import "core-js/modules/es.array.push.js";
import { qrCode, getQrInfo, bookGet } from '../api/article';
import { Toast } from 'vant';
export default {
  props: ['fdId'],
  data() {
    return {
      getDate: {},
      imgClass: ['qrcode-imgurl', 'qrcode-box', 'about-View-box-position'],
      visitStartTimeAll: '',
      visitEndTime: '',
      imgurl: ''
    };
  },
  methods: {
    getqrCode(id) {
      qrCode({
        infoId: id
      }).then(res => {
        this.imgurl = window.URL.createObjectURL(res.data);
        console.log(bytes);
      });
    },
    tobnt() {
      this.$router.go(-1);
    },
    formatDate(date) {
      let Y = date.getFullYear(),
        M = date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1,
        D = date.getDate() < 10 ? '0' + date.getDate() : date.getDate(),
        h = date.getHours() < 10 ? '0' + date.getHours() : date.getHours(),
        m = date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes();
      return Y + '-' + M + '-' + D + ' ' + h + ':' + m;
    }
  },
  created() {
    var _this$$route$query;
    if (this !== null && this !== void 0 && this.fdId) {
      this.imgClass = ['qrcode-addImgurl', 'qrcode-box-add', 'about-View-box-add'];
    }
    if (this !== null && this !== void 0 && this.fdId) {
      bookGet({
        id: this === null || this === void 0 ? void 0 : this.fdId
      }).then(res => {
        this.visitStartTimeAll = `${this.formatDate(new Date(res.data.data.visitStartTime))}`;
        this.visitEndTime = `${this.formatDate(new Date(res.data.data.visitEndTime))}`;
        this.getDate = res.data.data;
      });
    } else if ((_this$$route$query = this.$route.query) !== null && _this$$route$query !== void 0 && _this$$route$query.id) {
      var _this$$route$query2;
      bookGet({
        id: (_this$$route$query2 = this.$route.query) === null || _this$$route$query2 === void 0 ? void 0 : _this$$route$query2.id
      }).then(res => {
        this.visitStartTimeAll = `${this.formatDate(new Date(res.data.data.visitStartTime))}`;
        this.visitEndTime = `${this.formatDate(new Date(res.data.data.visitEndTime))}`;
        this.getDate = res.data.data;
        this.getqrCode(res.data.data.id);
      });
    } else {
      getQrInfo().then(res => {
        if (res.data.code == 101 || res.data.code == 102) {
          Toast({
            message: res.data.msg,
            className: "Toast-box"
          });
          this.$router.push('/viewInformation');
        } else {
          this.getqrCode(res.data.data.id);
          this.visitStartTimeAll = `${this.formatDate(new Date(res.data.data.visitStartTime))} 至 ${this.formatDate(new Date(res.data.data.visitEndTime))}`;
          this.getDate = res.data.data;
        }
      });
    }
  }
};