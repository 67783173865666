import "core-js/modules/web.dom-exception.stack.js";
import "core-js/modules/es.typed-array.at.js";
import "core-js/modules/es.typed-array.find-last.js";
import "core-js/modules/es.typed-array.find-last-index.js";
import "core-js/modules/es.typed-array.set.js";
import "core-js/modules/es.error.cause.js";
import "core-js/modules/es.array.push.js";
// @ is an alias to /src
// import HelloWorld from '@/components/HelloWorld.vue'
import { area, bookGet, getUnit, areaUpload, phone, imgView, personnelupdate, personneladd, personnelList, upload, reasonslist, getGarden } from '../api/article';
import GetView from './View.vue';
import QRcode from './QRcode.vue';
import { ImagePreview } from 'vant';
import { Toast } from 'vant';
export default {
  data() {
    return {
      value: '',
      popupvalue: '',
      aboutshow: 1,
      ViewId: '',
      type: '',
      hint: '',
      list: [],
      uploaderindex: null,
      healthPromiseState: 1,
      getUnitName: '',
      onSubmitform: false,
      onSubmitType: 2,
      getUnitlist: {
        name: '',
        pageNum: 1,
        pageSize: 20
      },
      personMobileList: [],
      // 校验提示语
      errorMessag: {
        getvisitStartTime: '',
        visitAreaName: '',
        visitGardenName: '',
        visitName: '',
        visitMobile: '',
        comeCause: ''
      },
      minDate: new Date(),
      vanTab: 0,
      // 手机号正则
      pattern: /^1[3|4|5|7|8|9]\d{9}$/,
      // 身份证正则
      patterncard: /(^\d{15}$)|(^\d{18}$)|(^\d{17}(\d|X|x)$)/,
      fdId: '',
      Viewindex: '',
      VisitorInfoshow: false,
      afterimgindex: null,
      ViewlistDate: null,
      setonLoad: false,
      visitStartTimeDate: new Date(),
      visitEndTimeCurrentDate: new Date(),
      Viewlist: [],
      imgurl: [],
      getname: '',
      loading: false,
      finished: false,
      refreshing: false,
      fdvisitStartTime: {
        visitStartTime: '',
        visitEndTime: ''
      },
      VisitorInfo: {
        visitorName: '',
        cardId: '',
        position: '',
        visitorMobile: '',
        carInfo: ''
      },
      actionSheetname: '',
      VisitorBookInfo: {
        // 来访事由
        comeCause: '',
        // 单位名称
        companyName: '',
        // 到访开始时间
        visitStartTime: '',
        // 到访结束时间（为空默认当天结束时间）
        visitEndTime: '',
        // 到访人名称
        visitName: '',
        // 员工手机
        employeeMobile: '',
        // 到访区域ID
        visitAreaId: '',
        visitGardenName: '',
        visitGardenId: []
      },
      listDate: [],
      listDatebox: [],
      getListDate: [],
      showPickerBox: [],
      companyId: '',
      showPicker: false,
      actionSheetTitle: '',
      actionshow: false,
      getvisitStartTime: '',
      getvisitEndTime: '',
      chosenContactId: '1'
    };
  },
  methods: {
    clickPreview(e, index) {
      var Previewindex = 0;
      if (typeof (e === null || e === void 0 ? void 0 : e.index) == "number") {
        Previewindex = e === null || e === void 0 ? void 0 : e.index;
      } else {
        Previewindex = this.uploaderindex;
      }
      console.log(e, Previewindex, 'clickPreview');
      ImagePreview({
        images: this.imgurl[Previewindex].map(v => {
          return v.dataurl;
        }),
        startPosition: index.index
      });
    },
    tobnt() {
      this.$router.go(-1);
    },
    addOnSubmit(e) {
      this.tabBnt();
    },
    //base64转化为Blob对象
    convertBase64ToBlob(imageEditorBase64) {
      var base64Arr = imageEditorBase64.split(",");
      var imgtype = "";
      var base64String = "";
      if (base64Arr.length > 1) {
        //如果是图片base64，去掉头信息
        base64String = base64Arr[1];
        imgtype = base64Arr[0].substring(base64Arr[0].indexOf(":") + 1, base64Arr[0].indexOf(";"));
      }
      // 将base64解码
      var bytes = atob(base64String);
      //var bytes = base64;
      var bytesCode = new ArrayBuffer(bytes.length);
      // 转换为类型化数组
      var byteArray = new Uint8Array(bytesCode);

      // 将base64转换为ascii码
      for (var i = 0; i < bytes.length; i++) {
        byteArray[i] = bytes.charCodeAt(i);
      }

      // 生成Blob对象（文件对象）
      return new Blob([bytesCode], {
        type: imgtype
      });
    },
    inputclke(e) {
      if (this.pattern.test(e)) {
        phone({
          fdMobilePhone: e
        }).then(res => {
          if (res.data.code !== 200) {
            var _res$data;
            Toast({
              message: res === null || res === void 0 ? void 0 : (_res$data = res.data) === null || _res$data === void 0 ? void 0 : _res$data.msg,
              className: "Toast-box"
            });
            this.VisitorBookInfo.visitMobile = '';
          }
        });
      }
    },
    // 表单提交校验
    onSubmit(e) {
      if (!this.errorMessag.getvisitStartTime) {
        this.onSubmitform = true;
        this.submit(this.onSubmitType);
      }
      console.log(e);
    },
    // 事由长度校验
    validator(e) {
      if (e.length > 50) {
        return false;
      } else {
        true;
      }
    },
    // 姓名长度校验
    validatorName(e) {
      if (e.length > 15) {
        return false;
      } else {
        true;
      }
    },
    onLoad() {
      // 异步更新数据
      // setTimeout 仅做示例，真实场景中一般为 ajax 请求
      console.log('// setTimeout 仅做示例，真实场景中一般为 ajax 请求');
      if (this.getUnitlist.pageNum > 1 && this.actionSheetTitle == '到访单位') {
        this.getUnitrm(this.getUnitlist);
      } else {
        this.loading = false;
      }
      this.getUnitlist.pageNum = this.getUnitlist.pageNum + 1;
    },
    debounce(fn, delay) {
      var timer = null;
      const _this = this;
      function add() {
        if (timer) clearTimeout(timer);
        timer = setTimeout(function () {
          // this.getUnitlist.name=fn
          _this.getUnitrm({
            ...this.getUnitlist,
            name: fn
          }, fn);
        }, delay);
      }
      add();
    },
    searchOnclk(e) {
      this.debounce(e, 500);
    },
    // 选择框事件
    showPickerclick(name) {
      this.showPickerBox = [];
      if (name == '到访单位') {
        this.getUnitrm(this.getUnitlist);
        this.getUnitlist.pageNum = 1;
        this.actionSheetname = '';
      } else {
        this.actionSheetname = '';
        this.clearCheck();
        getGarden({
          id: this.companyId
        }).then(res => {
          this.showPickerBox = res.data.data.map(v => {
            return {
              ...v,
              tyeps: false
            };
          });
        });
      }
      this.actionSheetTitle = name;
      this.showPicker = true;
    },
    noBnt() {
      this.showPicker = false;
      this.VisitorInfo = {
        visitorName: '',
        cardId: '',
        position: '',
        visitorMobile: '',
        carInfo: ''
      };
      this.VisitorInfoshow = false;
    },
    blobToBase64(blob) {
      return new Promise((resolve, reject) => {
        const fileReader = new FileReader();
        fileReader.onload = e => {
          resolve(e.target.result);
        };
        // readAsDataURL
        fileReader.readAsDataURL(blob);
        fileReader.onerror = () => {
          reject(new Error('blobToBase64 error'));
        };
      });
    },
    viewInitbox(data) {
      console.log(data, 'data');
      this.aboutshow = 1;
      this.Viewlist[data.index] = data;
      console.log(this.Viewlist);
    },
    afterRead(file, detail) {
      console.log(detail);
      var index = this.afterimgindex;
      file.status = 'uploading';
      file.message = '上传中...';
      upload(file).then(res => {
        var dataurl = window.URL.createObjectURL(this.convertBase64ToBlob(file.content));
        console.log(dataurl, 'dataurl');
        if (res.data.code == 200) {
          setTimeout(() => {
            const data = this.imgurl[index][detail.index];
            this.imgurl[index][detail.index] = {
              ...data,
              dataurl: dataurl,
              index: this.afterimgindex,
              uuidFileName: JSON.parse(res.data.msg).uuidName,
              name: JSON.parse(res.data.msg).name
            };
          }, 100);
          file.status = '';
          file.message = '';
        } else {
          var _res$data2;
          file.status = 'failed';
          file.message = '上传失败';
          Toast({
            message: res === null || res === void 0 ? void 0 : (_res$data2 = res.data) === null || _res$data2 === void 0 ? void 0 : _res$data2.msg,
            className: "Toast-box"
          });
        }
        console.log(this.imgurl, 'this.imgurl');
      }).catch(e => {
        file.status = 'failed';
        file.message = '上传失败';
        Toast({
          message: 上传失败,
          className: "Toast-box"
        });
      });
    },
    afterimg(e) {
      console.log(e, 'imgView');
      this.afterimgindex = e;
    },
    removeBy(e) {
      const arr = [];
      const Viewdata = [];
      const img = [];
      this.getListDate.map((v, index) => {
        if (index !== e) {
          arr.push(v);
          Viewdata.push(this.Viewlist[index]);
          img.push(this.imgurl[index]);
        }
      });
      this.listDate = this.listDate.map(v => {
        if (v.id == this.getListDate[e].id) {
          return {
            ...v,
            tyeps: false
          };
        } else {
          return v;
        }
      });
      console.log(arr, 'arr');
      this.getListDate = arr;
      this.Viewlist = Viewdata;
      this.imgurl = img;
    },
    personnelEdit(e) {
      this.VisitorInfo = e;
      this.showPicker = true;
      this.VisitorInfoshow = true;
      this.actionSheetTitle = '';
    },
    sheetclose() {
      this.VisitorInfoshow = false;
      this.VisitorInfo = {
        visitorName: '',
        cardId: '',
        position: '',
        visitorMobile: '',
        carInfo: ''
      };
    },
    // 去掉没有确认勾选
    clearCheck() {
      this.listDate = this.listDate.map(v => {
        const index = this.getListDate.findIndex(item => item.id === v.id);
        if (index > -1) {
          v.tyeps = true;
          return v;
        } else {
          v.tyeps = false;
          return v;
        }
      });
    },
    submit(tyep) {
      this.onSubmitType = tyep;
      if (this.onSubmitform) {
        var _this$$route$query2, _this$$route$query3, _this$$route$query4, _this$getListDate;
        console.log(tyep, 'this.onSubmitform');
        let imgurllength = false;
        let healthPromiseStatelist = false;
        this.imgurl.map((v, index) => {
          var _this$Viewlist$index;
          if (!v.length) {
            imgurllength = true;
          }
          if (this.healthPromiseState == 1 && !((_this$Viewlist$index = this.Viewlist[index]) !== null && _this$Viewlist$index !== void 0 && _this$Viewlist$index.type)) {
            healthPromiseStatelist = true;
          }
        });
        if (healthPromiseStatelist && this.healthPromiseState) {
          Toast({
            message: '请填写健康承诺书',
            className: "Toast-box"
          });
          this.onSubmitform = false;
          return;
        }
        if (imgurllength) {
          Toast({
            message: '必须上传一张图片！',
            className: "Toast-box"
          });
          this.onSubmitform = false;
          return;
        }
        const addVisitorBookInfo = this.VisitorBookInfo;
        const visitorInfoDate = this.getListDate.map((v, index) => {
          var _this$$route$query;
          console.log(this.imgurl[index].map(v => {
            return {
              name: v.name,
              uuidName: v.uuidFileName
            };
          }), this.imgurl, 'data');
          !((_this$$route$query = this.$route.query) !== null && _this$$route$query !== void 0 && _this$$route$query.id) && delete v.id;
          return {
            ...v,
            picture: JSON.stringify(this.imgurl[index].filter(v => {
              return !v.status;
            }).map(v => {
              return {
                name: v.name,
                uuidName: v.uuidFileName
              };
            })),
            promiseInfo: JSON.stringify({
              hint: this.hint,
              healthPromiseState: this.healthPromiseState,
              data: this.Viewlist[index]
            })
          };
        });
        ((_this$$route$query2 = this.$route.query) === null || _this$$route$query2 === void 0 ? void 0 : _this$$route$query2.id) && delete addVisitorBookInfo.visitAreaName;
        ((_this$$route$query3 = this.$route.query) === null || _this$$route$query3 === void 0 ? void 0 : _this$$route$query3.id) && delete addVisitorBookInfo.visitGardenName;
        console.log(visitorInfoDate, 'visitorInfoDate');
        const addsubmit = (_this$$route$query4 = this.$route.query) !== null && _this$$route$query4 !== void 0 && _this$$route$query4.id ? areaUpload : area;
        if (((_this$getListDate = this.getListDate) === null || _this$getListDate === void 0 ? void 0 : _this$getListDate.length) === 0) {
          Toast({
            message: '必须新增一个访客！',
            className: "Toast-box"
          });
          this.onSubmitform = false;
          return;
        }
        addsubmit({
          ...addVisitorBookInfo,
          approvalState: tyep,
          visitStartTime: `${this.visitStartTimeDate.toUTCString()}`,
          visitEndTime: `${this.visitEndTimeCurrentDate.toUTCString()}`,
          visitorInfo: visitorInfoDate
        }).then(res => {
          var _this$$route$query5;
          console.log(res, res.data.msg);
          this.aboutshow = 0;
          if ((_this$$route$query5 = this.$route.query) !== null && _this$$route$query5 !== void 0 && _this$$route$query5.id) {
            var _this$$route$query6;
            this.fdId = (_this$$route$query6 = this.$route.query) === null || _this$$route$query6 === void 0 ? void 0 : _this$$route$query6.id;
          } else {
            this.fdId = res.data.msg;
          }
        });
      }
    },
    vanIconView(e, i) {
      // window.sessionStorage.setItem('vanIconView', e)
      console.log(this.Viewlist, 'this.Viewlist');
      this.getname = e.visitorName;
      this.ViewId = e.id;
      this.Viewindex = i;
      this.ViewlistDate = this.Viewlist[i];
      // this.$router.push('/view')
      this.aboutshow = 3;
    },
    //
    listDatelik(arr) {
      const res = new Map();
      const arrDate = arr.filter(a => !res.has(a.id) && res.set(a.id, 1));
      if (arrDate[0]) {
        return arrDate.filter(e => {
          return e.tyeps == true;
        });
      } else {
        return arrDate;
      }
    },
    getUnitrm(e, name) {
      if (name) {
        e.pageNum = 1;
        this.getUnitlist.pageNum = 1;
        this.loading = true;
      } else {
        this.loading = false;
      }
      if (!e.pageNum) {
        e.pageNum = 1;
      }
      getUnit({
        name: e.name,
        pageNum: e.pageNum,
        pageSize: e.pageSize || 20
      }).then(res => {
        let getUnitlist = [];
        const getDate = res.data.data.list.map(v => {
          return {
            ...v,
            tyeps: false
          };
        });
        getUnitlist = name || e.pageNum == 1 ? [...getDate] : [...this.showPickerBox, ...getDate];
        this.showPickerBox = getUnitlist;
      });
    },
    tabBnt() {
      if (this.actionSheetTitle == '选择到访开始时间') {
        this.getvisitStartTime = `${this.formatDate(this.visitStartTimeDate)}`;
        this.visitStartTimeDate = this.visitStartTimeDate;
        if (this.getvisitEndTime && this.visitStartTimeDate.getTime() > this.visitEndTimeCurrentDate.getTime()) {
          console.log(this.visitStartTimeDate.getTime(), '---', this.visitEndTimeCurrentDate.getTime());
          this.errorMessag.getvisitStartTime = '结束时间不能早于开始时间';
        } else {
          this.errorMessag.getvisitStartTime = '';
        }
      } else if (this.actionSheetTitle == '选择到访截止时间') {
        this.getvisitEndTime = `${this.formatDate(this.visitStartTimeDate)}`;
        this.visitEndTimeCurrentDate = this.visitStartTimeDate;
        if (this.getvisitStartTime && this.visitStartTimeDate.getTime() > this.visitEndTimeCurrentDate.getTime()) {
          console.log(this.visitStartTimeDate.getTime(), '---选择到访截止时间', this.visitEndTimeCurrentDate.getTime());
          this.errorMessag.getvisitStartTime = '结束时间不能早于开始时间';
        } else {
          this.errorMessag.getvisitStartTime = '';
        }
      } else if (!this.actionSheetTitle) {
        if (this.vanTab == 0 || this.VisitorInfoshow) {
          const personnel = this.VisitorInfoshow ? personnelupdate : personneladd;
          personnel({
            ...this.VisitorInfo
          }).then(res => {
            const isAdd = !this.VisitorInfoshow;
            this.setpersonnelList(isAdd);
            this.VisitorInfo = {
              visitorName: '',
              cardId: '',
              position: '',
              visitorMobile: '',
              carInfo: ''
            };
          });
        } else {
          const arr = [...this.getListDate, ...this.listDatebox];
          console.log(this.listDatelik(arr), arr);
          if (this.listDatelik(arr)[0]) {
            this.getListDate = this.listDatelik(arr);
            const add = this.listDatelik(arr).map((v, index) => {
              if (this.imgurl.length < index + 1) {
                this.imgurl.push([]);
              }
              return {
                id: v.id,
                name: ''
              };
            });
            this.Viewlist = this.listDatelik([...this.Viewlist, ...add]);
          } else {
            this.getListDate = [];
            this.Viewlist = [];
            this.imgurl = [];
          }
          this.listDatebox = [];
        }
      } else {
        // if (this.actionSheetTitle == '事由') {
        //   this.VisitorBookInfo.comeCause = this.actionSheetname
        // } else
        if (this.actionSheetTitle == '到访园区') {
          this.VisitorBookInfo.visitGardenName = this.actionSheetname;
          this.errorMessag.visitGardenName = '';
        } else if (this.actionSheetTitle == '到访单位') {
          this.VisitorBookInfo.visitAreaName = this.actionSheetname;
          this.errorMessag.visitAreaName = '';
          if (this.actionSheetname) {
            this.VisitorBookInfo.visitGardenName = '';
          }
        }
      }
      this.showPicker = false;
    },
    setpersonnelList(isAdd = false) {
      personnelList({}).then(res => {
        console.log(res);
        // 如果是新增访客 则把接口中获取到的最后一个塞到 访客列表中的第一个
        if (isAdd) {
          if (this.listDate) {
            var _this$listDate;
            const selectId = (_this$listDate = this.listDate[this.listDate.length - 1]) === null || _this$listDate === void 0 ? void 0 : _this$listDate.id;
            this.listDate = res.data.data.map(item => {
              if (item.id === selectId) {
                item.tyeps = true;
                return item;
              }
              return item;
            });
            this.listDate[this.listDate.length - 1].tyeps = true;
            this.getListDate.push(this.listDate[this.listDate.length - 1]);
          }
        } else {
          this.listDate = res.data.data.map(v => {
            return {
              ...v,
              tyeps: false
            };
          });
        }
      });
    },
    pickershowlistDate(e) {
      const types = !this.listDate[e].tyeps;
      this.listDate[e].tyeps = types;
      this.listDatebox.push(this.listDate[e]);
      this.getListDate = this.getListDate.map(v => {
        if (this.listDate[e].id == v.id) {
          return {
            ...v,
            tyeps: types
          };
        } else {
          return v;
        }
      });
    },
    pickershowPickerBox(e) {
      console.log(e);
      // this.showPickerBox[e].tyep=true
      this.actionSheetname = this.showPickerBox[e].name;
      if (this.actionSheetTitle == '到访园区') {
        this.VisitorBookInfo.visitGardenId = [this.showPickerBox[e].id];
        this.hint = this.showPickerBox[e].hint;
        this.healthPromiseState = this.showPickerBox[e].healthPromiseState;
      } else if (this.actionSheetTitle == '到访单位') {
        this.companyId = this.showPickerBox[e].id;
        this.VisitorBookInfo.visitAreaId = this.showPickerBox[e].id;
      }
      this.showPickerBox = this.showPickerBox.map((v, i) => {
        if (i == e) {
          return {
            ...v,
            tyeps: true
          };
        } else {
          return {
            ...v,
            tyeps: false
          };
        }
      });
    },
    formatDate(date) {
      let Y = date.getFullYear(),
        M = date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1,
        D = date.getDate() < 10 ? '0' + date.getDate() : date.getDate(),
        h = date.getHours() < 10 ? '0' + date.getHours() : date.getHours(),
        m = date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes();
      return Y + '-' + M + '-' + D + ' ' + h + ':' + m;
    },
    formatter(type, val) {
      if (type === 'month') {
        return `${val}月`;
      } else if (type === 'day') {
        return `${val}日`;
      } else if (type === 'minute') {
        return `${val}分`;
      } else if (type === 'hour') {
        return `${val}时`;
      } else {
        return `${val}年`;
      }
    },
    uploaderClick(e) {
      this.uploaderindex = e;
    },
    filter(type, options) {
      if (type === 'minute') {
        return options.filter(option => option % 15 === 0);
      }
      return options;
    }
  },
  created() {
    var _this$$route$query7;
    this.setpersonnelList();
    this.type = (_this$$route$query7 = this.$route.query) === null || _this$$route$query7 === void 0 ? void 0 : _this$$route$query7.type;
    if (this.$route.query.id) {
      bookGet({
        id: this.$route.query.id
      }).then(res => {
        console.log(res);
        const data = res.data.data;
        const getpromiseInfo = data.visitorInfo.map(v => {
          let dataPromiseInfo = JSON.parse(v.promiseInfo);
          this.hint = dataPromiseInfo.hint;
          this.healthPromiseState = dataPromiseInfo.healthPromiseState;
          return dataPromiseInfo.data;
        });
        const getpicture = data.visitorInfo.map(v => {
          return JSON.parse(v.picture);
        });
        this.VisitorBookInfo = data;
        this.fdvisitStartTime = {
          visitStartTime: this.formatDate(new Date(data.visitStartTime)),
          visitEndTime: this.formatDate(new Date(data.visitEndTime))
        };
        this.getvisitStartTime = `${this.formatDate(new Date(data.visitStartTime))}`;
        this.getvisitEndTime = `${this.formatDate(new Date(data.visitEndTime))}`;
        this.getListDate = data.visitorInfo;
        this.Viewlist = getpromiseInfo;
        console.log(getpicture, 'getpicture');
        for (let index = 0; index < getpicture.length; index++) {
          Promise.all(getpicture[index].map(dateRes => {
            return imgView({
              uuidFileName: dateRes.uuidName
            }).then(res => {
              var dataurl = window.URL.createObjectURL(res.data);
              console.log(dataurl, 'dataurl');
              return this.blobToBase64(res.data).then(res => {
                return {
                  content: res,
                  index: index,
                  dataurl: dataurl,
                  uuidFileName: dateRes.uuidName,
                  name: dateRes.name
                };
              });
            });
          })).then(res => {
            this.imgurl.push(res);
          });
        }
      });
    }
  },
  components: {
    GetView: GetView,
    QRcode: QRcode
  }
};